<template>
  <div>
    <Header/> 
    <div class="content_text">
      <!-- <router-link :to="{path:'/'}">
        <img class="content_img_1" src="../../assets/content/logo_s.png">
      </router-link> -->
        <!-- <img :class="{'content_img_2':!contents,'content_img_2_one':contents == 1,'content_img_2_two':contents == 2,'content_img_2_three':contents == 3}" :src="logo"> -->
        <img class="content_img_2" :src="logo">
        <div calss="content_study">{{logo_title}}</div>
    </div>
    
    <div style="width:100%;display: flex;" class="content_div">
          <div v-for="item in courseTypes" :key="item" :class="{content:contents == item.id}" @click="content(item.id)">
              <img class="content_imgs_11" :src="item.image">
              <p class="classcard-title">{{item.title}}</p>
          </div>
    </div>
    <div class="svs">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" v-if="!contents">
        <el-menu-item index="0" id="el-menu-item">
          <div class="el-menu-item_one">{{recommend}}</div>
        </el-menu-item>
        <el-menu-item index="1" id="el-menu-item">
          <div class="el-menu-item_one">{{newest}}</div>
        </el-menu-item>
        <el-menu-item index="2" id="el-menu-item">
          <div>{{all}}</div>
        </el-menu-item>
      </el-menu>
      <el-menu :default-active="activeIndexs" class="el-menu-demo" mode="horizontal" @select="handleSelect" v-else>
        <!-- <el-menu-item index="0" id="el-menu-item" style="width:50%;">推薦</el-menu-item>
        <el-menu-item index="2" id="el-menu-item" style="width:50%;">全部</el-menu-item> -->
         <el-menu-item index="0" id="el-menu-item" style="width:50%;">
          <div class="el-menu-item_one">{{recommend}}</div>
        </el-menu-item>
        <!-- <el-menu-item index="1" id="el-menu-item" style="width:50%;">
          <div class="el-menu-item_one">最新</div>
        </el-menu-item> -->
        <el-menu-item index="2" id="el-menu-item" style="width:50%;">
          <div>{{all}}</div>
        </el-menu-item>
      </el-menu>      
      <div style="width:100%;height:1rem;"></div>
      <div v-if="index == 0" v-loading="loading"  element-loading-background="rgba(0, 0, 0, 0.8)">
        <div v-for="items in text_imgList" :key="items" :class="{text_1:!port || port == 'phone',text_1_pc:port == 'pc'}">
          <router-link :to="{path:'/training_schedule',query:{type:items.id}}">
            <img :src="items.image"  style="width:100%;height:100%;" />
            <!-- <div class="vip-icon">
              <img src="../../assets/content/radio_selected.png">
            </div> -->
          </router-link>
        </div>
      </div>

      <div class="text_newest" v-if="index == 1" v-loading="loading_1"  element-loading-background="rgba(0, 0, 0, 0.8)">
        <div v-for="items in newest_img_one_list" :key="items" :class="{text_1:!port || port == 'phone',text_1_pc:port == 'pc'}">
          <router-link :to="{path:'/training_schedule',query:{
            type:items.id
          }}">
            <img :src="items.image"  style="width:100%;height:100%;" />
            <!-- <div class="vip-icon">
              <img src="../../assets/content/radio_selected.png">
            </div> -->
          </router-link>
        </div>
        
        <div class="lesson-item" v-for="items in lesson_item_list" :key="items" @click="lessonItem(items.id)">
            <div class="lesson-item_1">
              <img :src="items.cover">
            </div>
            <div class="lesson-item_2">
                <p class="pl_1">{{items.title}}</p>
                <p class="pl_2">{{items.sub_title}}</p>
                <p class="pl_3">{{items.third_title}}</p>
            </div>
        </div>
      </div>

      <div class="text_2" v-if="index == 2" v-loading="loading_2"  element-loading-background="rgba(0, 0, 0, 0.8)">
        <div v-for="items in all_img_list" :key="items" :class="{text_1:!port || port == 'phone',text_1_pc:port == 'pc'}">
          <router-link :to="{path:'/training_schedule',query:{type:items.id}}">
            <img :src="items.image"  style="width:100%;height:100%;" />
            <!-- <div class="vip-icon">
              <img src="../../assets/content/radio_selected.png">
            </div> -->
          </router-link>
        </div>
      </div>
      <div style="width:100%;height:90px;"></div>
        <div style="width:100%;height:90px;"></div>
    </div>

    <Footer />
  </div>
</template>

<style>
.content{
  background:#806546;
}
.el-loading-spinner .path{
  stroke:#409eff;
}
.lesson-item_2 p{
  margin: 0 0 0 0;
}
.pl_1{
  font-family: Helvetica Neue;
    font-weight: 300;
    font-size: 12px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.pl_2{
  font-family: Helvetica Neue;
    font-weight: 500;
    font-size: 20px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.pl_3{
    font-family: Helvetica Neue;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.lesson-item_2{
  width: 65%;
  height: auto;
  margin:auto 5px auto 5px;
}
.lesson-item_1{
  width: 125px;
  height: auto;
}
.lesson-item_1 img{
  width: 100%;
  height:100%;
}
.lesson-item{
  height: auto;
  border-bottom: 1px solid #fff;
  display: flex;
  position: relative;
  min-height: 70px;
}
.vip-icon img{
  margin-top: 55px;
  width: 20px;
  height: 20px;
}
.vip-icon{
  position: absolute;
  padding: 0;
  right: 5px;
  top: 0;
  bottom: 0;
  /* background-color: rgba(255, 0, 0, 0.3); */
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-filter: drop-shadow(0 0 4px #d4b661);
  filter: drop-shadow(0 0 4px #d4b661);
}
.text_1{
   width: 100%;
   height: 7rem;
   border-bottom: 1px solid #fff;
 }
 .text_1_pc{
   width: 100%;
   height: 30vh;
   border-bottom: 1px solid #fff;
 }
  .el-menu.el-menu--horizontal{
    border-bottom:none;
  }
  .el-menu--horizontal > .el-menu-item{
    border-bottom: none;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover{
    background:#C49B6C !important;
  }
  .el-menu--horizontal{
    background:#C49B6C !important;
  }
  .el-menu--horizontal > .el-menu-item.is-active div{
    border-bottom: 2px solid #000;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    /* border-bottom: 2px solid #000; */
    border:none;
    color: rgba(0, 0, 0, 0.75);
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  #el-menu-item{
    width:33.33%;
    color: rgb(51, 51, 51);
    font-size: 18px;
  }
  .svs{
    height: 72vh;
    width: 100%;
    float:left;
    overflow: hidden auto;
  }
  .classcard-title{
    font-family: Helvetica Neue;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    color: #fff;
  }
  .content_imgs_11{
    margin-right: 5px;
    width: 40px;
    height: 40px;
  }
  .content_imgs_12{
    margin-right: 5px;
    width: 40px;
    height: 35px;
  }
  .content_imgs_13{
    margin-right: 5px;
    width: 40px;
    height: 30px;
  }
  .content_div div{
    max-width: 50%;
    height: 64px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -webkit-box-pack: center;
    justify-content: center;
    overflow-x: hidden;
    border: 1px solid #fff;
    border-left: none;
    -webkit-transition: background-color .3s ease-out;
    transition: background-color .3s ease-out;
    float:left;
  }
  .content_study{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .content_text{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .content_img_1{
    margin-right: 13px;
    width: 60px;
    height: 60px;
  }
  .content_img_2{
    width: 50px;
    height: 42px;
    margin-right: 8px;
  }
  .content_img_2_one{
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }
  .content_img_2_two{
    width: 50px;
    height: 45px;
    margin-right: 8px;
  }
  .content_img_2_three{
    width: 50px;
    height: 30px;
    margin-right: 8px;
  }
</style>
<script>
import Header from "../Header";
import Footer from "../Footer";
import {apiCreateCourseTypes,apiCourseTypesDetail} from "@/request/api";
export default {
  components:{Header,Footer},
  props: {
    title: String
  },
  data(){
    return {
      loading: false,
      loading_1: false,
      loading_2: false,
      activeIndex: '0',
      activeIndexs:'0',
      index:0,
      text_imgList: [],
      newest_img_one_list:[],
      lesson_item_list:[],
      all_img_list:[],
      contents:0,
      logo:require('@/assets/content/logo_yi.png'),
      logo_title:'',
      query:0,
      in_regard_to:'',
      team_training:'',
      team_members:'',
      recommend:'',
      newest:'',
      all:'',
      courseTypes:[],
      // 区分pc还是手机端
      port:'',
    }
  },
  created(){
      let query = this.$route.query['sort_id'];
      let lang_id = this.$store.state.lang_id;
      this.port = this.$store.state.port;

      // 简繁体判断
      if(lang_id == 1){
        this.logo_title = this.$zh_ft('study');
        this.in_regard_to = this.$zh_ft('in_regard_to');
        this.team_training = this.$zh_ft('team_training');
        this.team_members = this.$zh_ft('team_members');

        this.recommend = this.$zh_ft('recommend');
        this.newest = this.$zh_ft('newest');
        this.all = this.$zh_ft('all');
      }else{
        this.logo_title = this.$zh_jt('study');
        this.team_training = this.$zh_jt('team_training');
        this.in_regard_to = this.$zh_jt('in_regard_to');
        this.team_members = this.$zh_jt('team_members');

        this.recommend = this.$zh_jt('recommend');
        this.newest = this.$zh_jt('newest');
        this.all = this.$zh_jt('all');
      }

      let data = {
        lang_id:lang_id,
        recommend:1
      };
      this.query = query;
      if(query > 0){
        this.contents = query;
        data['parent_id'] = query;

        apiCourseTypesDetail(query,{
          lang_id:lang_id
        }).then(res=>{
          if(res.code == 200){
             this.logo = res.data.image;
             this.logo_title = res.data.title;
          }
        }).catch(error=>{
          console.log(error);
        })
      }
       apiCreateCourseTypes(data).then(res=>{
          if(res.code == 200){
            this.text_imgList = res.data.courseTypeList;
          }
        }).catch(error=>{
          console.log(error);
        })

        // 拿到 三大類型
        apiCreateCourseTypes({
          lang_id:lang_id
        }).then(res=>{
          if(res.code == 200){
            let content_type = [];
            for(let i=0; i<res.data.courseTypeList.length; i++){
              // 只取三條
              if(i < 3){
                if(res.data.courseTypeList[i].parent_id == 0){
                  content_type.push(res.data.courseTypeList[i]);
                }
              }
            }
            this.courseTypes =  content_type;
          }
        }).catch(error=>{
          console.log(error);
        })
  },
 watch:{
  },
  methods:{
    lessonItem(id){
      this.$router.push({
        path:'/training_schedule/'+id
      });
    },
    content(val){
      location.href="/content?sort_id="+val;
      // location.reload();
    },
    handleSelect(key) {
       this.index = key;
       this.loading = true;
       let data = {};
       if(this.index == 0){
          data = {
            lang_id:this.$store.state.lang_id,
            recommend:1
          }
          if(this.query){
            data['parent_id'] = this.query;
          }
          apiCreateCourseTypes(data).then(res=>{
            if(res.code == 200){
              this.text_imgList = res.data.courseTypeList;
            }
            this.loading = false;
          }).catch(error=>{
            this.loading = false;
            console.log(error);
          })
       }else if(this.index == 1){
         this.loading_1 = true;
         apiCreateCourseTypes({
           lang_id:this.$store.state.lang_id,
           is_new:1,
         }).then(res=>{
            if(res.code == 200){
              this.newest_img_one_list = res.data.courseTypeList;
              this.lesson_item_list = res.data.courseList;
            }
            this.loading_1 = false;
          }).catch(error=>{
            this.loading_1 = false;
            console.log(error);
          })
       }else if(this.index == 2){
         this.loading_2 = true;
          data = {
            lang_id:this.$store.state.lang_id
          }
         if(this.query){
          data['parent_id'] = this.query;
         } 
         apiCreateCourseTypes(data).then(res=>{
            if(res.code == 200){
              let j = [];
              for(let i=0; i<res.data.courseTypeList.length; i++){
                if(res.data.courseTypeList[i].parent_id > 0){
                  j.push(res.data.courseTypeList[i]);
                }
              }
              this.all_img_list = j;
            }
            this.loading_2 = false;
          }).catch(error=>{
            this.loading_2 = false;
            console.log(error);
          })
       }
    }
  }
}
</script>

